import donateImage from "../assets/images/donate.jpg";

const Donors = () => {
  return (
    <div className="bg-cardBg md:pt-24">
      <div className="container max-w-[1392px] mx-auto px-4 md:px-8 py-12 text-xl font-bold text-darkBgl">
        <div>
          <img
            className="rounded-t-lg object-contain md:rounded-none md:rounded-l-lg max-w-[330px] md:w-[150px] mx-auto mb-4"
            src={donateImage}
            alt="donation pics"
          />
        </div>
        <div className="flex flex-col md:items-center bg-cardBgl rounded-lg p-8">
          <h2 className="font-bold text-[#585858] uppercase pb-2 text-4xl">Donations</h2>
          <p>Please Contact the Founder, Elroi Humanitarian Foundation via: +234 8138205521</p>
        </div>
      </div>

    </div>
  )
}

export default Donors
