import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'G-8NYM2035NC'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    < BrowserRouter>
        <App />
    </BrowserRouter>
);