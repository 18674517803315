import React from 'react';
import Supports from '../components/Supports';

const Sponsors = () => {
  return (
    <section className="bg-cardBg md:py-8 md:pt-24">
      <Supports />
    </section>
  )
}

export default Sponsors
