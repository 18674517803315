import React from 'react';
import StoryCard from '../components/StoryCard';

const Stories = () => {
  return (
    <section className="bg-cardBg md:py-8 md:pt-24">
      <StoryCard />
    </section>
  )
}

export default Stories
