import ngoImageOne from "../assets/images/outreachone.jpg";
import ngoImageCam from "../assets/images/ngo1.jpg";

const Gallery = () => {
  return (    
      <div className="container max-w-[1392px] mx-auto px-4 py-8">
        <h2 className="card-header">Gallery</h2>
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              />
            </div>
          </div>
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
              />
            </div>
          </div>
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={ngoImageOne}
              />
            </div>
          </div>
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
              />
            </div>
          </div>
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={ngoImageCam}
              />
            </div>
          </div>
          <div className="gallery-card">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Gallery;
