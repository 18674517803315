import hambugerIcon from "../assets/icons/hamburger.svg";
import closeIcon from "../assets/icons/close.svg";
import LogoIcon from "../assets/icons/logo.jpg";
import { Link } from 'react-router-dom';
import DonateBtn from "./DonateBtn";

const Header = () => {
  
  return (
    <header className="bg-darkBg text-cardBg">
      <div className="container max-w-[1392px] mx-auto md:flex justify-end items-center py-3 px-4 md:px-8 md:relative z-[2]">
        <div className="flex justify-between md:absolute top-0 left-16 md:border-4 border-donateBg">
          <Link to="/">
            <img
              src={LogoIcon}
              alt="hamburger icon"
              className="w-[50px] h-[50px] md:w-[auto] md:h-[120px]"
            />
          </Link>          
          <img
            src={hambugerIcon}
            onClick={() => {
                document.getElementById("navContainer").classList.toggle("hidden");
              }}
            alt="hamburger icon"
            className="w-[40px] h-[40px] md:hidden cursor-pointer"
          />
        </div>
        <ul
          className="hidden flex flex-col md:flex md:flex-row justify-evenly h-screen md:h-auto items-center gap-4 fixed md:sticky inset-0 bg-darkBg md:bg-transparent z-30"
          id="navContainer"
          onClick={() => {
            document.getElementById("navContainer").classList.add("hidden");
          }}
        >
          <Link to="stories">
            <li className="nav-items">Our Stories</li>
          </Link>
          <Link to="about">
            <li className="nav-items">About us</li>
          </Link>
          <Link to="media">
            <li className="nav-items">Media</li>
          </Link>
          <Link to="contact">
            <li className="nav-items">Contact Us</li>
          </Link>
          <DonateBtn title="Donate" />
        <div>
          <img
            src={closeIcon}
            alt="close icon"
            className="md:hidden absolute top-8 right-8 cursor-pointer"
          />
        </div>
        </ul>
      </div>
    </header>
  );
};

export default Header;
