import { Link } from 'react-router-dom';

const DonateBtn = (props) => {
  return (
        <Link to="donors" className="py-2 px-4 font-bold rounded bg-donateBg hover:bg-[#d9600c] duration-200">            
          {props.title}
        </Link>
  )
}

export default DonateBtn