import React from 'react';
import Gallery from '../components/Gallery';

const Media = () => {
  return (
    <section className="bg-cardBg md:py-8 md:pt-24">
      <Gallery />      
    </section>
  )
}

export default Media
