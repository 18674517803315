import React from "react";

const HomePageCard = () => {
  return (
    <div className="container max-w-[1392px] mx-auto px-4 py-8 text-base text-darkBgl">
      <h2 className="card-header">Our Stories</h2>
      <div className="flex flex-wrap md:p-8">
        <div className="home-card">
          <h4>WHO WE ARE</h4>
          <p>
            Elroi Humanitarian Foundation: is a non-government
            organization established with the stark aim of reaching out to
            people across the globe, however such categories of people includes
            orphans, less privileged children and adults, disables and those
            with critical medical challenges. Our passion and commitment is to
            help every child live healthy, access to good education towards
            achieving their creative purposes by exploring their God giving
            potentials. Furthermore to help those who are going through critical
            challenges either medical, financial or spiritually etc.
          </p>
          <p>
            Date: <span className="font-light">23rd September, 2023</span>
          </p>
        </div>
        <div className="home-card">
          <h4>CORE VALUE</h4>
          <p>
            1. Compassion: This is our sole driving force we are concerned about
            the wareferism of others with ultimate priority of being part of
            their success story. <br /> 2. Espousal: We know that durable changes
            requires constant and frantic effort of all in the spirit Of
            conglomeration. This is why we do our best to integrate and build
            participation from both local and international partners to helps
            contribution in giving people the smile and the future they
            deserved. <br /> 3. Integrity: we believe in moral soundness and
            uprightness the goal is to enable us to render an outstanding
            humanitarian services without dubious and questionable modules of
            operando.<br /> 4. Capacity – building: the primary objective of the
            program is to be a pencil in God’s hands to bring out the best
            hidden potentials skills and abilities in all the beneficiaries for
            a better living.<br /> 5. Accountability: we believe absolutely in
            transparency and accountability to everyone involved in one way or
            the other, that we help mass participation.
          </p>
          <p>
            Date: <span className="font-light">23rd September, 2023</span>
          </p>
        </div>
        <div className="home-card">
          <h4>MISSION</h4>
          <p>
          The mission of Elroi Humanitarian Foundation is to ensure that children, men and women who do not have access to school, good living and medical attention are giving a reasonable support for a better living. This organization is also sadled with responsibility of empowering people to actualize their dreams with the help of Almighty God, it also help in bringing our brighter future from those whom circumstances or society has limited.
          </p>
          <p>
            Date: <span className="font-light">23rd September, 2023</span>
          </p>
        </div>
        <div className="home-card">
          <h4>VISION</h4>
          <p>
            Our vision is to create a future in which every society has equal opportunity to live a health life, accomplish dreams and explore a God given potentials without any limitations.
          </p>
          <p>
            Date: <span className="font-light">23rd September, 2023</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
