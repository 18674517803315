import React, { useEffect } from "react";
import { Carousel, initTE } from "tw-elements";
import AboutUs from "../components/AboutUs";
import CarouselCard from '../components/CarouselCard'
import Gallery from "../components/Gallery";
import StoryCard from "../components/StoryCard";

const Home = () => {
  useEffect(() => {
    initTE({ Carousel });
  }, []);

  return (
    <>
      <section className="bg-cardBg">
        <CarouselCard />
      </section>
      <section className="bg-cardBg md:py-8">
        <StoryCard />
      </section>
      <section className="bg-[#ededed] md:py-8">
        <AboutUs />
      </section>
      <section className="bg-cardBg md:py-8">
        <Gallery />
      </section>
    </>
  );
};

export default Home;
