import React from 'react'

const Contact = () => {
  return (
    <section className="bg-cardBg md:pt-24">
      <div className="container max-w-[1392px] px-4 md:px-8 mx-auto">
        <div className="flex flex-col justify-center items-center w-full md:w-[60%] max-w-[700px] mx-auto py-8">
          <h1 className="font-bold text-4xl pb-4 text-darkBg">Lets get in Touch</h1>
          <form className="flex flex-col w-full" onSubmit={((e) => {e.preventDefault(); alert("shiit")})}>
            <input type="text" placeHolder="Enter your name...." required id="name" className="bg-cardBgl p-4 rounded outline-none" />
            <input type="email" placeHolder="Enter your Email...." required id="email" />
            <input type="phone" placeHolder="Enter Phone...." required id="phone" />
            <textarea placeholder="Your message...." required id="message" />
            <div className="md:text-center">
              <button type="submit" className="bg-darkBg hover:bg-darkBgl py-4 px-12 rounded-lg font-bold text-cardBg">Send</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
