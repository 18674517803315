import React from 'react';
import { Link } from 'react-router-dom';

const NoPage = () => {
  return (
    <div className='bg-cardBg h-screen py-48 text-center'>
      <h1 className="text-3xl">Error 404!</h1>
      <p>No Page found on this url, suggest something to us via contact form please!</p>
      <Link to="/" className='underline text-blue-500'>
        &lt;&lt;&lt; Navigate back to Home Page
      </Link>
    </div>
  )
}

export default NoPage
