import { Link } from 'react-router-dom';
import LogoIcon from "../assets/icons/logo.jpg";
import DonateBtn from './DonateBtn';

const Footer = () => {
  return (
    <footer>
      <div className="container max-w-[1392px] mx-auto py-8 px-4 md:px-8 text-cardBgl">
        <div className="md:flex justify-between">
            <div className="first-item order-1">
              <Link to="stories">
                <li>Our Stories</li>
              </Link>
              <Link to="about">
                <li>About us</li>
              </Link>
              <Link to="sponors/supports">
                <li>Supports</li>
              </Link>
              <Link to="media">
                <li>Media</li>
              </Link>
            </div>
            <div className="second-item order-2">
              <Link to="contact">
                <li>Contact Us</li>
              </Link>
              <Link to="sponors/supports">
                <li>Sponsors</li>
              </Link>
              <Link to="/">
                <li>Up-Coming Events</li>
              </Link>
            </div>
            <div className="flex items-end order-3 mt-8 md:mt-0">
                <DonateBtn title="Support us" />
            </div>
          <div className="left-aside pt-12 md:pt-0 md:flex gap-8">
            <div className="mb-8">
              <h4 className="font-bold text-[#585858] uppercase pb-2">Head Office</h4>
              <p>No. 6. Shop 3. Kaita Plaza,</p>
              <p>Gwagwalada,</p>
              <p>FCT-Abuja,</p>
              <p>Nigeria.</p>
            </div>
          </div>
          <div>
            <Link to="/">
                <img
                  src={LogoIcon}
                  alt="hamburger icon"
                  className="w-[50px] h-[50px] md:w-[auto] md:h-[120px] mb-12"
                />
              </Link>
          </div>
        </div>
      </div>
    </footer>
  )

}

export default Footer
