import ngoImageFour from "../assets/images/ngo4.jpg";
import ngoImageThree from "../assets/images/ngo3.jpg";

const Supports = () => {
  return (
    <>
      <div className="container max-w-[1392px] mx-auto px-4 py-8 text-base text-darkBgl">
        <h2 className="card-header">Sponsors and Supporters</h2>
        <div className="flex flex-col justify-center gap-8 p-4  md:p-16">
          <div className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:flex-row">
            <div className="w-full">
              <img
              className="rounded-t-lg object-contain md:rounded-none md:rounded-l-lg"
              src={ngoImageThree}
              alt=""
              />
            </div>
            <div className="about-card">
              <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
                Visit title
              </h5>
              <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
              <p className="text-xs text-neutral-500 dark:text-neutral-300">
                Last updated 3 mins ago
              </p>
            </div>
          </div>          
          <div className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:flex-row">
            <div className="w-full md:order-1">
              <img
              className="rounded-t-lg object-contain md:rounded-none md:rounded-tr-lg md:rounded-br-lg"
              src={ngoImageFour}
              alt=""
              />
            </div>
            <div className="about-card">
              <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
                Visit title
              </h5>
              <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
              <p className="text-xs text-neutral-500 dark:text-neutral-300">
                Last updated 3 mins ago
              </p>
            </div>
          </div>          
        </div>
      </div>
    </>
  )
}

export default Supports