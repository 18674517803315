import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Media from "./Pages/Media";
import Stories from "./Pages/Stories";
import Donors from "./Pages/Donors";
import Sponsors from "./Pages/Sponsors";
import NoPage from "./Pages/NoPage";



function App() {
  return (
      <Routes>
        <Route path="/" element={ <Layout /> } >
          <Route index element={ <Home /> } />
          <Route path="about" element={ <About /> } />
          <Route path="contact" element={ <Contact /> } />
          <Route path="media" element={ <Media /> } />
          <Route path="stories" element={ <Stories /> } />
          <Route path="donors" element={ <Donors /> } />
          <Route path="sponors/supports" element={ <Sponsors /> } />
        </Route>
      <Route path="*" element={ <NoPage /> } />
    </Routes>
  );
}

export default App;
